<template>
    <div>
      <el-dialog
        title="在线账户开户-平安结算通"
        :visible.sync="dialogFormVisible"
        :before-close="close"
      >
        <el-form label-position="left" ref="childForm" :model="childForm">
          <el-form-item
            size="small"
            label="公司名称"
            :label-width="formLabelWidth"
            prop="appName"
          >
            <el-input
              v-model="childForm.appName"
              :disabled="true"
              placeholder="请输入公司名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
  
          <el-form-item
            size="small"
            label="平安一般结算户"
            :label-width="formLabelWidth"
            prop="mainAccount"
          >
            <el-input
              v-model="childForm.mainAccount"
              :disabled="!allowInput"
              placeholder="请输入结算通结算账号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="submit"
        
            v-if="allowInput"
            v-loading.fullscreen.lock="fullscreenLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {
    isvCreateSettleChannelApi
  } from '@/request/api';
  export default {
    props: {
      dialogFormVisible: { type: Boolean, default: false },
      form: {
        type: Object,
        default: () => ({
          appName: '',
          isvId:'',
          mainAccount: '',
          id: '',
        }),
      },
      allowInput: { type: Boolean, default: false },
    },
    data() {
      return {
        isDisable: true,
        formLabelWidth: '160px',
        childForm: this.form,
        fullscreenLoading: false,
      };
    },
    watch: {
      form() {
        this.childForm = { ...this.form };
      },
      childForm: {
        handler(val) {
          let testForm = val;
          this.isDisable = testForm.mainAccount ? false : true;
        },
        deep: true,
      },
    },
    methods: {
      async channelSubmit(formData) {
        try {
          this.fullscreenLoading = true;
          const res = await isvCreateSettleChannelApi(formData);
          this.fullscreenLoading = false;
          if (res.status === 200) {
            this.$refs.childForm.resetFields();
            this.$message.success('配置成功');
            this.$emit('update-table');
            this.$emit('update:dialogFormVisible', false);
          } else {
            this.$message.error(res.message);
            this.$refs.childForm.resetFields();
          }
        } catch (error) {
          this.fullscreenLoading = false;
          this.$emit('update:dialogFormVisible', false);
        }
      },
      submit() {
        this.channelSubmit(this.childForm);
      },
      close() {
        this.$refs.childForm.resetFields();
        this.$emit('update:dialogFormVisible', false);
      },
    },
  };
  </script>
  
  <style lang="stylus" scoped>
  .upload_box {
      display: flex;
  }
  </style>
  