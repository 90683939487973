<template>
  <div class="content">
    <div class="header">
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">企业名称</span>
            <el-input
              class="right"
              size="small"
              v-model.trim="isvName"
              placeholder="请输入企业名称"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">证件号码</span>
            <el-input
              class="right"
              size="small"
              v-model.trim="idNo"
              placeholder="请输入证件号码"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">产业园</span>
            <el-select
              class="right"
              size="small"
              v-model="name"
              placeholder="请选择"
              @change="selectIndustryRes"
            >
              <el-option
                v-for="item in industryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">状态</span>
            <el-select
              class="right"
              size="small"
              v-model="stateName"
              placeholder="请选择"
              @change="selectStateRes"
            >
              <el-option
                v-for="item in stateList"
                :key="item.state"
                :label="item.stateName"
                :value="item.state"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">接入时间</span>
            <el-date-picker
              class="right"
              v-model="dateResult"
              type="daterange"
              size="small"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="btn-content">
            <el-button type="primary" size="medium" @click="handleQuery"
              >查询</el-button
            >
            <el-button size="medium" @click="handleClear">清空</el-button>
            <el-button
              type="primary"
              size="medium"
              @click="addNewPlat"
              v-perm="'/isv/add'"
              >添加新平台</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table_content">
      <cesTable
        :isButton="true"
        :isSelection="false"
        :isIndex="true"
        :isPagination="true"
        :tableData="tableData"
        :tableCols="tableCols"
        :pagination="pagination"
        :loading="loading"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @hadnleOperate="hadnleOperate"
      ></cesTable>
    </div>
    <!-- 添加or编辑平台 -->
    <cesdialog
      :dialogFormVisible.sync="dialogFormVisible"
      :title="dialogFormTitle"
      :isAddOrEdit="isAddOrEdit"
      :industryList="industryList"
      :form="platForm"
      @updateTableList="updateTableList"
    ></cesdialog>
    <!-- 网商渠道 -->
    <internetchanneldialog
      :dialogFormVisible.sync="internetChannelDialogFormVisible"
      :channelSetVisible.sync="channelSetVisible"
      :form="internetForm"
      :allowInput="internetAllowInput"
      @update-table="queryList"
    ></internetchanneldialog>
    <!-- 工行 -->
    <icbcChannelDialog
      :dialogFormVisible.sync="icbcChannelDialogFormVisible"
      :form="icbcForm"
      :allowInput="icbcAllowInput"
      @update-table="queryList"
    ></icbcChannelDialog>
    <!-- 邮储 -->
    <postalStorageChannelDialog
      :dialogFormVisible.sync="postalChannelDialogFormVisible"
      :allowInput="postalAllowInput"
      :form="postalStorageForm"
    ></postalStorageChannelDialog>

    <postalXiamen
      :dialogFormVisible.sync="xiamenChannelDialogFormVisible"
      :allowInput="xiamenAllowInput"
      :form="xiamenStorageForm"
    ></postalXiamen>
    <!-- 平安财资平台 -->
    <SPABSTARChannelDialog
      :dialogFormVisible.sync="SPABSTARChannelDialogFormVisible"
      :allowInput="SPABSTARAllowInput"
      :form="SPABSTARForm"
    ></SPABSTARChannelDialog>
    <!-- 光大 -->
    <cebStorageChannelDialog
      :dialogFormVisible.sync="cebChannelDialogFormVisible"
      :allowInput="cebAllowInput"
      :form="cebStorageForm"
    ></cebStorageChannelDialog>
    <!-- 工行聚富通 -->
    <icbcv2ChannelDialog
      :dialogFormVisible.sync="icbcv2ChannelDialogFormVisible"
      :form="icbcv2Form"
      :allowInput="icbcv2AllowInput"
      @update-table="queryList"
    ></icbcv2ChannelDialog>
    <!-- 工行聚富通2.0 -->
    <icbcv3ChannelDialog
      :dialogFormVisible.sync="icbcv3ChannelDialogFormVisible"
      :form="icbcv3Form"
      :allowInput="icbcv3AllowInput"
      @update-table="queryList"
    ></icbcv3ChannelDialog>
    <!-- 平安银行 -->
    <spabankChannelDialog
      :dialogFormVisible.sync="spabankChannelDialogFormVisible"
      :form="spabankForm"
      :allowInput="spabankAllowInput"
      @update-table="queryList"
    ></spabankChannelDialog>
    <spabankLicenseDialog
      :dialogFormVisible.sync="spabankLicenseChannelDialogFormVisible"
      :form="spabankLicenseForm"
      :allowInput="spabankLicenseAllowInput"
      @update-table="queryList"
    ></spabankLicenseDialog>
    <settleChannelDialog
      :dialogFormVisible.sync="settleVisible"
      :form="settleBankForm"
      :allowInput="settleAllowInput"
      @update-table="queryList"
    ></settleChannelDialog>
    <!-- 渠道开关配置 -->
    <channelDialog
      :dialogVisible.sync="allChannelDialogFormVisible"
      :isvId="isvId"
    ></channelDialog>
    <!-- 平台配置 -->
    <platformSetDialog
      :platformDialogVisible.sync="platformSetDialogVisible"
      :isvId="isvId"
    ></platformSetDialog>
    <!-- 主渠道开关配置 已合并功能 -->
    <!-- <mainChannelDialog
      :dialogVisible.sync="mainChannelDialogFormVisible"
      :isvId="isvId"
      :mainKeyDate="mainKeyDate"
    ></mainChannelDialog> -->
    <!-- 平台停用及启用 -->
    <platDialog
      :platDialogVisible.sync="platDialogVisible"
      :plat="platObj"
      @update-tabel="queryList"
    ></platDialog>
    <!-- 渠道参数设置 集合 -->
    <channelSet
      :channelSetVisible.sync="channelSetVisible"
      :isvId="isvId"
      :keyDate="keyDate"
      :rowObj="currentRowObj"
      @show-dialog="showDialog"
    ></channelSet>
  </div>
</template>

<script>
import { platManageList, queryIndustryList, channelApp } from '@/request/api';
import cesTable from '@/components/table';
import cesdialog from './components/dialog';
import internetchanneldialog from './components/internetChannelDialog';
import icbcChannelDialog from './components/icbcChannelDialog';
import icbcv2ChannelDialog from './components/icbcv2ChannelDialog';
import icbcv3ChannelDialog from './components/icbcv3ChannelDialog';
import spabankChannelDialog from './components/spabankChannelDialog';
import spabankLicenseDialog from './components/spabankLicenseDialog.vue';
import postalStorageChannelDialog from './components/postalStorageChannelDialog';
import SPABSTARChannelDialog from './components/SPABSTARChannelDialog';
import cebStorageChannelDialog from './components/cebStorageChannelDialog';
import postalXiamen from './components/postXiamen.vue'
import channelDialog from './components/channelDialog';
import platformSetDialog from './components/platformSetDialog';
// import mainChannelDialog from './components/mainChannelDialog'
import platDialog from './components/platDialog';
import channelSet from './components/channelSet';
import settleChannelDialog from './components/spabSettleChannelDialog.vue'
export default {
  components: {
    cesTable,
    cesdialog,
    internetchanneldialog,
    icbcChannelDialog,
    icbcv2ChannelDialog,
    icbcv3ChannelDialog,
    spabankChannelDialog,
    spabankLicenseDialog,
    postalStorageChannelDialog,
    cebStorageChannelDialog,
    channelDialog,
    platformSetDialog,
    SPABSTARChannelDialog,
    // mainChannelDialog,
    platDialog,
    channelSet,
    settleChannelDialog,
    postalXiamen
  },
  data() {
    return {
      stateList: [
        {
          state: '0',
          stateName: '停用',
        },
        {
          state: '1',
          stateName: '启用',
        },
      ],
      currentRowObj: {},
      isvName: '',
      idNo: '',
      industryId: '',
      stateName: '',
      state: '',
      tableData: [],
      dateResult: '',
      dialogFormVisible: false,
      dialogFormTitle: '',
      // add true edit false
      isAddOrEdit: true,
      platForm: {},
      tableCols: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          op: [
            { text: '编辑平台信息', class: 'track', permission: '/isv/edit' },
            {
              text: '停用/启用支付',
              class: 'cancel',
              permission: '/isv/changeState',
            },
            {
              text: '渠道参数配置',
              class: 'channelSet',
              permission: '/isv/channelConfig',
            },
            {
              text: '渠道开关',
              class: 'channel',
              permission: '/isv/channelSwitch',
            },
            {
              text: '平台配置',
              class: 'platformSet',
              permission: '/isv/isvSetting',
            },
          ],
        },
        { label: '企业名称', prop: 'isvName' },
        { label: '证件号码', prop: 'idNo' },
        { label: '联系人', prop: 'contact' },
        { label: '联系电话', prop: 'contactNumber' },
        { label: '产业园', prop: 'industryName' },
        { label: '开通渠道数', prop: 'channelNumbers' },
        { label: '状态', prop: 'stateName' },
        { label: '回调地址', prop: 'notifyUrl' },
        { label: '接入后台时间', prop: 'created' },
      ],
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      industryList: [],
      // 产业园名称
      name: '',
      internetChannelDialogFormVisible: false,
      internetForm: {
        companyName: '',
        svid: '',
        appid: '',
        publicKey: '',
        privateKey: '',
        companyId: '',
      },
      icbcForm: {
        companyName: '',
        bankName: '',
        bankNumber: '',
        publicKey: '',
        privateKeyAddress: '',
        tableAddress: '',
      },
      icbcv2Form: {
        companyName: '',
        bankName: '',
        bankNumber: '',
        publicKey: '',
        privateKeyAddress: '',
        tableAddress: '',
      },
      icbcv3Form: {},
      spabankForm: {},
      spabankLicenseForm: {},
      SPABSTARForm: {},
      postalStorageForm: {
        companyName: '',
        number: '',
        sysCode: '',
        companyId: '',
        signAccount: '',
      },
      xiamenStorageForm: {
        appName: '',
        id: '',
        privateKey: '',
        merchantId: '',
        accountNo: '',
        mainAccount:'',
        publicKey:''
      },
      cebStorageForm: {
        appName: '',
        busiNum: '',
        equityFundAccNo: '',
        id: '',
        marginAccNo: '',
      },
      icbcChannelDialogFormVisible: false,
      icbcv2ChannelDialogFormVisible: false,
      icbcv3ChannelDialogFormVisible: false,
      spabankChannelDialogFormVisible: false,
      spabankLicenseChannelDialogFormVisible: false,
      postalChannelDialogFormVisible: false,
      xiamenChannelDialogFormVisible: false,
      cebChannelDialogFormVisible: false,
      allChannelDialogFormVisible: false,
      SPABSTARChannelDialogFormVisible: false,
      platformSetDialogVisible: false,
      // mainChannelDialogFormVisible: false,
      icbcAllowInput: false,
      icbcv2AllowInput: false,
      icbcv3AllowInput: false,
      spabankAllowInput: false,
      spabankLicenseAllowInput: false,
      internetAllowInput: false,
      postalAllowInput: false,
      xiamenAllowInput:false,
      SPABSTARAllowInput: false,
      cebAllowInput: false,
      isvId: '',
      keyDate: null,
      mainKeyDate: null,
      // 平台禁用及停用
      platObj: {},
      platDialogVisible: false,
      channelSetVisible: false,
      loading: false,
      settleBankForm:{},
      settleAllowInput:false,
      settleVisible:false
    };
  },
  created() {
    this.queryIndustryList();
    this.queryList();
  },
  methods: {
    async queryList() {
      try {
        const date1 = this.dateResult && this.dateResult[0];
        const date2 = this.dateResult && this.dateResult[1];
        const beginDate = date1 ? date1 + ' 00:00:00' : '';
        const endDate = date2 ? date2 + ' 23:59:59' : '';
        const p = {
          page: {
            current: this.pagination.pageNum,
            size: this.pagination.pageSize,
          },
          isvName: this.isvName,
          idNo: this.idNo,
          industryId: this.industryId,
          state: this.state,
          beginDate: beginDate,
          endDate: endDate,
        };
        this.loading = true;
        const res = await platManageList(p);
        this.loading = false;
        if (res.status === 200 && res.data) {
          this.tableData = res.data.items || [];
          if (res.data.total && p.page.current === 1) {
            this.pagination.total = res.data.total;
          }
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        this.loading = false;
      }
    },
    updateTableList() {
      this.queryList();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.queryList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.queryList();
    },
    // 产业园list
    async queryIndustryList() {
      try {
        const res = await queryIndustryList();
        this.industryList = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    handleClear(evt) {
      // 清空
      let target = evt.target;
      if (target.nodeName == 'SPAN') {
        target = evt.target.parentNode;
      }
      target.blur();
      this.dateResult = '';
      this.idNo = '';
      this.name = '';
      this.industryId = '';
      this.stateName = '';
      this.state = '';
      this.isvName = '';
    },
    selectStateRes(state) {
      this.state = state;
    },
    selectIndustryRes(id) {
      this.industryId = id;
    },
    // 操作
    async hadnleOperate(a, b, c) {
      const rowObj = b;
      const opObj = c;
      const obj = {
        isvName: rowObj.isvName,
        industryId: rowObj.industryId,
        industryName: rowObj.industryName,
        contact: rowObj.contact,
        contactNumber: rowObj.contactNumber,
        idNo: rowObj.idNo,
        id: rowObj.id,
        notifyUrl: rowObj.notifyUrl,
      };
      switch (opObj.class) {
        // 编辑平台信息
        case 'track':
          this.platForm = { ...obj };
          this.platForm.isvId = rowObj.isvId;
          this.dialogFormVisible = true;
          this.isAddOrEdit = false;
          this.dialogFormTitle = '编辑平台信息';
          break;
        // 渠道开关配置
        case 'channel':
          this.isvId = rowObj.isvId;
          this.allChannelDialogFormVisible = true;
          break;
        // 渠道开关配置
        case 'platformSet':
          this.isvId = rowObj.isvId;
          this.platformSetDialogVisible = true;
          break;
        case 'channelSet':
          this.isvId = rowObj.isvId;
          this.keyDate = new Date().getTime();
          this.currentRowObj = rowObj;
          this.channelSetVisible = true;
          break;
        // 渠道开关配置
        // case 'mainChannel':
        //   this.isvId = rowObj.isvId
        //   this.mainKeyDate = new Date().getTime()
        //   this.mainChannelDialogFormVisible = true
        //   break
        // 启用or停用
        case 'cancel':
          this.platObj = { ...rowObj };
          this.platObj.onOff = this.platObj.state === 1;
          this.platDialogVisible = true;
          break;
        default:
          break;
      }
    },
    handleQuery() {
      this.pagination.pageNum = 1;
      this.queryList();
    },
    addNewPlat() {
      this.dialogFormVisible = true;
      this.isAddOrEdit = true;
      this.dialogFormTitle = '添加新平台';
      this.platForm = {};
    },
    editPlatMessage() {
      // 编辑平台信息
      this.dialogFormVisible = true;
      this.isAddOrEdit = false;
      this.dialogFormTitle = '编辑平台信息';
    },
    showDialog(isvId, code) {
      console.log(code)
      switch (code) {
        // 工行参数配置
        case 'ICBC':
          this.showIcbc(isvId, code);
          break;
        case 'ANTB':
          this.showAntb(isvId, code);
          break;
        case 'ANTB_V2':
          this.showAntb(isvId, code);
          break;
        case 'PSBC':
          this.showPsbc(isvId, code);
          break;
        case 'PSBCXM':
          this.showXiamen(isvId,code);
          break
        case 'SPAB_STAR':
          this.showSpabStar(isvId, code);
          break;
        case 'CEB':
          this.showCeb(isvId, code);
          break;
        case 'ICBC_V2':
          this.showIcbcV2(isvId, code);
          break;
        case 'ICBC_V3':
          this.showIcbcV3(isvId, code);
          break;
        case 'SPABANK':
          this.showSpabank(isvId, code);
          break;
        case 'SPAB_LICENSE':
          this.showSpabankLicense(isvId, code);
          break;
        case 'SPAB_SETTLE':
          this.showSettleBank(isvId, code);
      }
    },
    async showIcbc(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.icbcForm = { ...info };
            this.icbcAllowInput = false;
            this.icbcChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              bankOrganization: '',
              ecis: '',
              publicKey: '',
              privateKeyAddress: '',
              tableAddress: '',
              id: this.currentRowObj.id,
            };
            this.icbcForm = { ...obj };
            this.icbcAllowInput = true;
            this.icbcChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async showAntb(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.internetForm = { ...info };
            this.internetForm.institutionCode = code;
            this.internetAllowInput = false;
            this.internetChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              isvOrgId: '',
              appId: '',
              publicKey: '',
              privateKey: '',
              id: this.currentRowObj.id,
              institutionCode: code,
              isvId: isvId,
            };
            this.internetForm = { ...obj };
            this.internetAllowInput = true;
            this.internetChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showPsbc(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.postalStorageForm = { ...info };
            this.postalAllowInput = false;
            this.postalChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              merchId: '',
              outSysCode: '',
              id: this.currentRowObj.id,
              accountNo: '',
            };
            this.postalStorageForm = { ...obj };
            this.postalAllowInput = true;
            this.postalChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showXiamen(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.xiamenStorageForm = { ...info };
            this.xiamenAllowInput = false;
            this.xiamenChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              merchantId: '',
              id: this.currentRowObj.id,
              accountNo: '',
              mainAccount:'',
              publicKey:''
            };
            this.xiamenStorageForm = { ...obj };
            this.xiamenAllowInput = true;
            this.xiamenChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showSpabStar(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.SPABSTARForm = { ...info };
            this.SPABSTARAllowInput = false;
            this.SPABSTARChannelDialogFormVisible = true;
          } else {
            this.SPABSTARForm = {
              appName: this.currentRowObj.isvName,
              id: this.currentRowObj.id,
            };
            this.SPABSTARAllowInput = true;
            this.SPABSTARChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showCeb(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.cebStorageForm = { ...info };
            this.cebAllowInput = false;
            this.cebChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              busiNum: '',
              equityFundAccNo: '',
              id: this.currentRowObj.id,
              marginAccNo: '',
            };
            this.cebStorageForm = { ...obj };
            this.cebAllowInput = true;
            this.cebChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showIcbcV2(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.icbcv2Form = { ...info };
            this.icbcv2AllowInput = false;
            this.icbcv2ChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              groupAppId: '',
              groupEncryptKey: '',
              groupPrivateKey: '',
              appId: '',
              encryptKey: '',
              privateKey: '',
              encryptType: 'AES',
              signType: 'RSA2',
              id: this.currentRowObj.id,
            };
            this.icbcv2Form = { ...obj };
            this.icbcv2AllowInput = true;
            this.icbcv2ChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showIcbcV3(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.icbcv3Form = { ...info };
            this.icbcv3AllowInput = false;
            this.icbcv3ChannelDialogFormVisible = true;
          } else {
            const obj = {
              appName: this.currentRowObj.isvName,
              appId: '',
              accountNo: '',
              accountName: '',
              appIdSub: '',
              publicKey: '',
              encryptKey: '',
              privateKey: '',
              sftpUserName: '',
              id: this.currentRowObj.id,
              signType: 'RSA2',
              encryptType: 'AES',
            };
            this.icbcv3Form = { ...obj };
            this.icbcv3AllowInput = true;
            this.icbcv3ChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showSpabank(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.spabankForm = { ...info };
            this.spabankForm.accessMode = info.accessMode || 0;
            this.spabankAllowInput = false;
            this.spabankChannelDialogFormVisible = true;
          } else {
            const obj = {
              isvId: isvId,
              appName: this.currentRowObj.isvName,
              appPrivateKey: '',
              appSecret: '',
              mainAccount: '',
              appId: '',
              merchCode: '',
              accessMode: 0,
              id: this.currentRowObj.id,
            };
            this.spabankForm = { ...obj };
            this.spabankAllowInput = true;
            this.spabankChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showSettleBank(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.settleBankForm = { ...info };
         
            this.settleAllowInput = false;
            this.settleVisible = true;
          } else {
            const obj = {
              isvId: isvId,
              appName: this.currentRowObj.isvName,
              mainAccount: '',
              appId: '',
              id: this.currentRowObj.id,
            };
            this.settleBankForm = { ...obj };
      
            this.settleAllowInput = true;
            this.settleVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showSpabankLicense(isvId, code) {
      try {
        const p = { isvId: isvId, institutionCode: code };
        const res = await channelApp(p);
        if (res.status === 200 && res.data) {
          const info = res.data.data;
          if (info) {
            this.spabankLicenseForm = { ...info };

            this.spabankLicenseAllowInput = false;
            this.spabankLicenseChannelDialogFormVisible = true;
          } else {
            const obj = {
              isvId: isvId,
              appName: this.currentRowObj.isvName,
              appPrivateKey: '',
              appSecret: '',
              mainAccount: '',
              appId: '',
              merchCode: '',
              id: this.currentRowObj.id,
            };
            this.spabankLicenseForm = { ...obj };
            this.spabankLicenseAllowInput = true;
            this.spabankLicenseChannelDialogFormVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
    display: flex;
    flex-direction: column;
}

.line {
    background-color: gray;
    height: 1px;
    width: 100%;
}

.header {
    padding:10px 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
