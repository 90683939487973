<template>
    <div>
      <el-dialog
        title="在线账户开户-厦门邮储"
        :visible.sync="dialogFormVisible"
        :before-close="close"
      >
        <el-form label-position="left" ref="childForm" :model="childForm">
          <el-form-item
            size="small"
            label="公司名称"
            :label-width="formLabelWidth"
            prop="appName"
          >
            <el-input
              v-model="childForm.appName"
              :disabled="true"
              placeholder="请输入公司名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            size="small"
            label="集团客户号"
            :label-width="formLabelWidth"
            prop="merchantId"
          >
            <el-input
              v-model="childForm.merchantId"
              :disabled="!allowInput"
              placeholder="请输入集团客户号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            size="small"
            label="签约主账号"
            :label-width="formLabelWidth"
            prop="mainAccount"
          >
            <el-input
              v-model="childForm.mainAccount"
              :disabled="!allowInput"
              placeholder="请输入签约主账号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
          v-show="allowInput"
          size="small"
          label="公钥文件"
          :label-width="formLabelWidth"
          prop="publicKey"
        >
          <span>请上传后缀是cer的文件</span>
          <input
            id="fileupload"
            type="file"
            accept=".cer"
            @change="changeUpload($event, 'publicKey')"
          />
        </el-form-item>
          <el-form-item
            v-show="allowInput"
            size="small"
            label="私钥文件"
            :label-width="formLabelWidth"
            prop="privateKey"
          >
            <span>请上传后缀是jks的文件</span>
            <input
              id="fileupload2"
              type="file"
              accept=".jks"
              @change="changeUpload($event, 'privateKey')"
            />
          </el-form-item>
        </el-form>
        <div v-if="allowInput" slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="submit"
            :disabled="isDisable"
            v-loading.fullscreen.lock="fullscreenLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { postalXiamenChannelV2 } from '@/request/api'
  export default {
    props: {
      dialogFormVisible: { type: Boolean, default: false },
      form: {
        type: Object,
        default: () => ({
          appName: '',
          merchantId: '',
          id: '',
          accountNo: '',
          privateKey: '',
          publicKey:"",
          mainAccount:''
        }),
      },
      allowInput: { type: Boolean, default: false },
    },
    data() {
      return {
        isDisable: true,
        formLabelWidth: '120px',
        childForm: this.form,
        fullscreenLoading: false,
        fileList: [],
      }
    },
    watch: {
      form() {
        this.childForm = { ...this.form }
      },
      childForm: {
        handler(val) {
          let testForm = val
          this.isDisable =
            (testForm.merchantId && testForm.mainAccount)
              ? false
              : true
        },
        deep: true,
      },
    },
    methods: {
      async postalStorageChannelSubmit() {
        let p = {
          id: this.childForm.id,
          merchantId: this.childForm.merchantId,
         mainAccount:this.childForm.mainAccount,
          accountNo: this.childForm.accountNo,
          publicKey: this.childForm.publicKey,
          privateKey: this.childForm.privateKey,
        }
        if (!this.childForm.publicKey) {
        this.$message({
          type: 'error',
          message: '请上传公钥文件',
        })
        return
      }
        if (!this.childForm.privateKey) {
          this.$message({
            type: 'error',
            message: '请上传私钥文件',
          })
          return
        }
        try {
          this.fullscreenLoading = true
          const res = await postalXiamenChannelV2(p)
          this.fullscreenLoading = false
          if (res.status == 200) {
            this.clearForm()
            this.$emit('update-table')
            this.$emit('update:dialogFormVisible', false)
          } else {
            this.$message.error(res.message)
          }
        } catch (error) {
          this.fullscreenLoading = false
        }
      },
      changeUpload(event, item) {
        const reader = new FileReader()
        if (event.target.files[0]) {
          reader.readAsText(event.target.files[0], 'utf8')
          reader.onload = (event) => {
            this.$nextTick(() => {
              this.childForm[item] = event.target.result
            })
          }
        } else {
          this.childForm[item] = ''
        }
      },
      clearForm() {
        this.$refs.childForm.resetFields()
        this.$refs.childForm.resetFields()
      var file = document.getElementById('fileupload')
      var file2 = document.getElementById('fileupload2')
      file.value = ''
      file2.value = ''
      },
      submit() {
        this.postalStorageChannelSubmit()
      },
      close() {
        this.clearForm()
        this.$emit('update:dialogFormVisible', false)
      },
    },
  }
  </script>
  
  <style lang="stylus" scoped>
  .upload_box {
      display: flex;
  }
  </style>
  